.sticky-buttons {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 999;
}

.sticky-button {
  background-color: var(--accent-color);
  color: var(--primary-color);
  font-size: large;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
  width: 50px;
  text-decoration: none;
}

.sticky-button:hover {
  transition: width  0.3s ease-in-out, margin-left 0.3s ease-in-out;
}

.sticky-button.expanded {
  width: 180px;
  margin-left: calc(100% - 180px); /* Adjust margin to maintain alignment */
}

.icon {
  margin-right: 10px;
}

.button-text {
  display: none;
  margin-left: 5px;
  text-decoration: none;
  /* no wrap; */
  white-space: nowrap;

}

.sticky-button.expanded .button-text {
  display: inline;
}

.hide {
  width: 40px;
}

.hide-icon {
  transition: transform 0.3s ease-in-out;
}

.rotate {
  transform: rotate(180deg);
}
 
@media (max-width: 768px) {
  .sticky-buttons {
    position: fixed;
    top: 97%;
    /* top: 0%; */
    bottom: 0;
    gap: 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 999;
    background-color: var(--primary-color); 
  }

  .sticky-button {
  background-color: var(--primary-color);
  color: var(--accent-color);
  font-size: large;
  border: 1px solid var(--accent-color);
  border-color: var(--accent-color);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  text-decoration: none;
  padding: 15px; 
}

.button-text {
  margin: auto;
  display: inline;
  text-decoration: none;
  white-space: nowrap;
  color: var(--accent-color);
  font-size: large;

}
.icon {
  margin-left: 3px
}
}
  