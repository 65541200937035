@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");
@import "~react-toastify/dist/ReactToastify.css";
@import "~react-big-calendar/lib/css/react-big-calendar.css";
@import "leaflet/dist/leaflet.css";
/* @import "~react-datepicker/dist/react-datepicker.css"; */



.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--lighter-color);
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  text-align: justify;
}

.content {
  flex: 1;
  padding-top: 176px;
}

@media screen and (max-width: 768px) {
  .content{
    padding-top: 48px;
  }
}

.footer {
  flex-shrink: 0;
}

.carousel {
  position: relative;
  width: 100%;
}

.card {
  width: 500px;
  height: 100px;
}

.leaflet-container {
  width: 100wh;
  height: 100vh;
}

.fade-in-image {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Custom CSS */
.custom-radio {
  /* Hide the default radio button */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* Use a custom icon for the radio button */
  background-color: transparent;
  border: 2px solid var(--primary-color); /* Color of the outer circle */
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  /* Center the custom radio button within its container */
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  margin-right: 0.5rem; /* Adjust spacing as needed */
}

.custom-radio:checked::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.6em; /* Size of the inner circle */
  height: 0.6em; /* Size of the inner circle */
  background-color: var(--accent-color); /* Color of the inner circle */
  border-radius: 50%; /* Make it a circle */
}

/* Custom CSS for checkboxes */
.custom-checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* Use a custom icon for the checkbox */
  background-color: transparent;
  border: 2px solid var(--primary-color); /* Change the border color as needed */
  border-radius: 3px; /* Adjust border radius as needed */
  width: 1.2em;
  height: 1.2em;
  /* Center the custom checkbox within its container */
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  margin-right: 0.5rem; /* Adjust spacing as needed */
  padding: 0px;
}

/* Style the inner checkmark when the checkbox is checked */
.custom-checkbox:checked::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0px;
  transform: translate(-50%, -50%);
  width: 0.6em; /* Size of the inner checkmark */
  height: 0.6em; /* Size of the inner checkmark */
  background-color: var(--secondary-color); /* Color of the inner checkmark */
  border-radius: 1px; /* Adjust border radius as needed */
}


.custom-pagination .pagination {
  --cui-pagination-bg: white;
  --cui-pagination-hover-color: #379683;
  --cui-pagination-active-bg: var(--secondary-color);
  --cui-pagination-active-hover-color: #5d5c61;
  --cui-pagination-disabled-color: #d4dee6;
  --cui-pagination-disabled-hover-color: #d4dee6;
  --cui-pagination-active-color: white;
  --cui-pagination-active-hover-color: white;
  --cui-pagination-disabled-bg: white;
  --cui-pagination-disabled-hover-bg: white;
  --cui-pagination-disabled-color: #d4dee6;
  --cui-pagination-border-color: var(--secondary-color);
  --cui-pagination-color: var(--secondary-color);
  --cui-pagination-border-color: var(--secondary-color);
}

.custom-pagination .pagination .page-item.active .page-link {
  z-index: 0;
}

.rbc-event .rbc-day-slot .rbc-background-event {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.rbc-calendar .rbc-month-row .rbc-row-content .rbc-row .rbc-row-segment .rbc-event{
  background-color: var(--secondary-color);
}

.rbc-calendar .rbc-month-row .rbc-row-content .rbc-row .rbc-row-segment .rbc-button-link{
  color: var(--accent-color);
}

.rbc-calendar .rbc-time-view .rbc-row-segment .rbc-event {
  background-color: var(--secondary-color);
}

/* .rbc-calendar .rbc-month-view .rbc-month-row .rbc-day-bg.rbc-today {
  background-color: var(--light-color);
}

.rbc-calendar .rbc-time-view .rbc-time-content .rbc-day-slot.rbc-time-column.rbc-now.rbc-today{
  background-color: var(--light-color);
} */

.playfair-display {
  font-family: "Popins", sans-serif;
}

.playfair-display h1,
.playfair-display h2,
.playfair-display h3,
.playfair-display h4,
.playfair-display h5,
.playfair-display h6 ,
.playfair-display.navbar
{
  font-family: "Playfair Display", serif;
  font-weight: 700;
  font-style: normal;
}