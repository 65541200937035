@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
  --primary-color: #000000;
  --secondary-color: #118461;
  --accent-color: #fbb03c;
  --light-color: #fddfb1;
  --lighter-color: #feefd8;
  --lighest-color: #fff7ec;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.pagination {
  --cui-pagination-bg: #5d5c61;
  --cui-pagination-hover-color: #379683;
  --cui-pagination-active-color: #379683;
  --cui-pagination-active-hover-color: #5d5c61;
  --cui-pagination-disabled-color: #d4dee6;
  --cui-pagination-disabled-hover-color: #d4dee6;
}
