/* Sidebar styles */
.menu-sidebar {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  transition: 0.5s;
  padding-top: 40px;
}

.menu-sidebar.open {
  left: 0;
}

.menu-sidebar-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Adjust width as needed */
  height: 100%;
  background-color: black;
  opacity: 0.9;
  color: white;
  padding: 20px;
}

.menu-items {
  padding: 10px;
}

/* Close icon styles */
.close-icon {
  position: absolute;
  color: white;
  height: 20px;
  width: 20px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.menu-dropdown {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  transition: all 0.8s ease; /* Add transition effect */
}

.menu-dropdown.open {
  display: flex;
  opacity: 1; /* Ensure visibility when open */
  transition: all 0.5s ease; /* Add transition effect */
}

/* Dragging animation */
.menu-sidebar.dragging {
  transition: none; /* Disable transition */
}

.menu-sidebar-content.dragging {
  transition: none; /* Disable transition */
}

.menu-sidebar.dragging.open {
  left: 100%; /* Close the menu on dragging */
}